export const REQUIRED_FIELD_ERROR = 'Mandatory';
export const NON_NUMBER_ERROR = 'Please enter valid number';
export const NON_NEGATIVE_ERROR = "Can't be negative value"
export const MAX_SALARY_ERROR = "Can't exceed 100000000"
export const MIN_SALARY_ERROR = "Can't be less than 1 lakh"
export const MAX_CHARACTER_ERROR = "Can't be more than 2500 characters"
export const NO_DOCUMENT_ERROR = 'Please upload at least one document';
export const NO_EDIT = 'You do not have permission to edit';
export const NO_VIEW = 'You do not have permission to view';
export const NO_DOWNLOAD = 'You do not have permission to download';
export const NO_CREATE = 'You do not have permission to create';
export const COMMENTS_ALREADY_SUBMITTED = "Can't delete/edit the interviews which has comments";
export const NO_FILE_ERROR = 'Please upload the file';
export const MAX_MONTH_ERROR = "Can't be more than 12";
export const NO_PAY_DETAILS_ACCESS = "You do not have access to view the pay details";
export const EXPECTED_PAY_PERCENTAGE_VALUE = 1.2 //20% greater than the last drawn ctc
export const INTERVIEW_COMMENTS_NOT_AVAILABLE ="Interviewer comments not available";
export const INTERVIEW_COMMENTS_AVAILABLE ="Interviewer comments available";
export const MAX_CHAR = "Can't exceed 500 characters"


export const RATING_MARKS = [
  {
    value: 0,
    label: '0',
    display_opinion: 'No'
  },
  {
    value: 1,
    label: '1',
    display_opinion: 'No'
  },
  {
    value: 2,
    label: '2',
    display_opinion: 'May be'
  },
  {
    value: 3,
    label: '3',
    display_opinion: 'May be'
  },
  {
    value: 4,
    label: '4',
    display_opinion: 'Yes'
  },
  {
    value: 5,
    label: '5',
    display_opinion: 'Yes'
  },
];

//confirmation messages
export const confirmation_messages = {
  documents_submit: 'Do you want to submit the documents?',
  candidate_additional_submit: 'Do you want to submit the details?',
  candidate_save_and_next: 'Do you want save and next?',
  candidate_web_additional_submit: 'Do you want to submit the details?',
  candidate_web_save_and_next: 'Do you want save and next?',
  bulk_profiles_upload: 'Do you want to bulk upload the profiles?',
  preferences_submit: 'Do you want to submit the preferences?',
  bulk_test_scores_upload: 'Do you want to bulk upload the test scores?',
  bulk_interviews_schedule: 'Do you want to schedule the bulk interivews?',
  add_notes_submit: 'Do you want to add notes?',
  reject_candidate: "Do want to REJECT the candidate?",
  approve_candidate: 'Do you want to APPROVE the candidate?',
  comments_submit: 'Do you want to submit the interview comments?',
  schedule_interviews_submit: 'Do you want to submit the scheduled interviews?',
  schedule_interview_remove: 'Do you want to remove the scheduled interview?',
  considered_roles_submit: 'Do you want to submit the considered roles?',
  cases_not_to_be_done_submit: 'Do you want to submit the cases not to be done?',
  recruiters_comment_submit: 'Do you want to submit the recruiter comments?',
  academic_details_submit: 'Do you want to submit the academic details?',
  rank_cards_submit: 'Do you want to submit the rank card details?',
  work_experience_submit: 'Do you want to submit the full-time work experience details?',
  personal_details_submit: 'Do you want to submit the personal details?',
  skills_submit: 'Do you want to submit the skills?',
  bgv_submit:"Do you want to submit the Personal Details (Additional)?",
  proposal_submit: "Do you want to submit the proposal details?",
  create_profile: 'Do you want to create the profile?',
  update_profile: 'Do you want to update the profile?',
  test_score_submit: 'Do you want to submit the test details?',
  test_score_remove: 'Do you want to remove the test score?',
  action_create: "Do you want to create the action?",
  action_update: "Do you want to update the action?",
  branch_create: "Do you want to create the branch?",
  branch_update: "Do you want to update the branch?",
  case_create: "Do you want to create the case?",
  case_update: "Do you want to update the case?",
  college_create: "Do you want to create the college?",
  college_update: "Do you want to update the college?",
  degree_create: "Do you want to create the degree?",
  degree_update: "Do you want to update the degree?",
  department_create: "Do you want to create the department?",
  department_update: "Do you want to update the department?",
  sub_department_create: "Do you want to create the sub department?",
  sub_department_update: "Do you want to update the sub department?",
  designation_create: "Do you want to create the designation?",
  designation_update: "Do you want to update the designation?",
  domain_create: "Do you want to create the domain?",
  domain_update: "Do you want to update the domain?",
  event_create: "Do you want to create the event?",
  event_update: "Do you want to update the event?",
  resume_source_create: "Do you want to create the resume source?",
  resume_source_update: "Do you want to update the resume source?",
  stage_create: "Do you want to create the stage?",
  stage_update: "Do you want to update the stage?",
  status_create: "Do you want to create the status?",
  status_update: "Do you want to update the status?",
  email_template_create: "Do you want to create the email template?",
  email_template_update: "Do you want to update the email template?",
  user_role_create: "Do you want to create the role?",
  user_role_update: "Do you want to update the role?",
  user_create: "Do you want to create the user?",
  user_update: "Do you want to update the user?",
  entrance_exam_create: "Do you want to create the entrance exam?",
  entrance_exam_update: "Do you want to update the entrance exam?",
  activate_candidate: "Do you want to move the candidate to active profiles?",
  pincode_create: "Do you want to create the pin code?",
  pincode_update: "Do you want to update the pin code?",
  request_docs:"Do you want to send the mail?",
  referral_submit: "Do you want to refer a candidate?",
  tag_create: "Do you want to create the tag?",
  tag_update: "Do you want to update the tag?",
  change_password: "Do you want to change the password?"
}

export const error_messages = {
  academics_gap: "Please enter your reason for the academic gap.",
  employment_gap: "Please enter your reason for the employment gap.",
  no_ug_message: "Please add at least one under graduation",
  experience_details: "The overall experience & experience timeline(s) duration doesn't match",
  attachments_size_error:"The total size of all attachments exceeded 20MB. Please review and re-upload.",
  resume_not_available: "Please upload your resume",
  mandatory_fields_missing:"Please fill all the mandatory fields in Registration Details",
  sync_failure: "Failed to sync, please try again.",
  candidate_joined_failure: "Failed to move the candidate further, please try again.",
  work_email_already_exist: "Email already in use. Please enter a different one."
}
